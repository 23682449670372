<template>
  <template v-for="(module, index) in modules">
    <component
      :is="ModuleComponents[module.__typename]"
      v-if="'__typename' in module && module.__typename in ModuleComponents"
      :key="module.id"
      :data-name="module.__typename"
      :data="module"
      :data-module="module.__typename"
      class="module"
      :horizontal-scroll-timeline="horizontalScrollTimeline"
    >
    </component>
  </template>
</template>

<script setup lang="ts">
import type { Component } from 'vue';
import ContentText from '~/components/modules/ContentText.vue';
import ContentContactPerson from '~/components/modules/ContentContactPerson.vue';
import ContentImageMood from '~/components/modules/ContentImageMood.vue';
import ContentImageWall from '~/components/modules/ContentImageWall.vue';
import ContentList from '~/components/modules/ContentList.vue';
import ContentTeam from '~/components/modules/ContentTeam.vue';
import ImageLandscape from '~/components/modules/ImageLandscape.vue';
import ImageMasonry from '~/components/modules/ImageMasonry.vue';
import ImagePortrait from '~/components/modules/ImagePortrait.vue';
import ImageTwoPortrait from '~/components/modules/ImageTwoPortrait.vue';
import TeaserAboutUs from '~/components/modules/TeaserAboutUs.vue';
import TeaserDefault from '~/components/modules/TeaserDefault.vue';
import TeaserFeaturedProjects from '~/components/modules/TeaserFeaturedProjects.vue';
import TeaserHighlight from '~/components/modules/TeaserHighlight.vue';
import TeaserList from '~/components/modules/TeaserList.vue';
import TeaserProjects from '~/components/modules/TeaserProjects.vue';
import ContentTextGreeting from '~/components/modules/ContentTextGreeting.vue';

type Module = {
  id: string;
  __typename: string;
  [key: string]: unknown;
};

const ModuleComponents: Record<string, Component> = {
  ContentTextRecord: ContentText,
  ContentTextGreetingRecord: ContentTextGreeting,
  ContentContactPersonRecord: ContentContactPerson,
  ContentImageMoodRecord: ContentImageMood,
  ContentImageWallRecord: ContentImageWall,
  ContentListRecord: ContentList,
  ContentTeamRecord: ContentTeam,
  ImageLandscapeRecord: ImageLandscape,
  ImageMasonryRecord: ImageMasonry,
  ImagePortraitRecord: ImagePortrait,
  ImageTwoPortraitRecord: ImageTwoPortrait,
  TeaserAboutRecord: TeaserAboutUs,
  TeaserDefaultRecord: TeaserDefault,
  TeaserProjectRecord: TeaserProjects,
  TeaserFeaturedProjectRecord: TeaserFeaturedProjects,
  TeaserHighlightRecord: TeaserHighlight,
  TeaserListRecord: TeaserList,
};

defineProps<{
  modules: (Module | {})[];
  horizontalScrollTimeline?: gsap.core.Timeline;
}>();
</script>

<style scoped lang="scss"></style>
