<template>
  <div ref="containerElement" class="teaser-about-us" data-horizontal-module>
    <h2 class="title">
      <span class="line line1 text-4xl" data-speed="0.75">{{
        data.titleLine1
      }}</span
      ><br />
      <span class="line line2 text-4xl-display" data-speed="1.2">{{
        data.titleLine2
      }}</span>
    </h2>
    <div class="content">
      <div class="textwrapper" data-speed="0.75">
        <div class="inner">
          <StructuredText
            v-if="data.text"
            class="text-lg text"
            :data="data.text"
            :render-block="renderBlock"
          />
          <Link
            v-if="data.link"
            class="link button-primary"
            :link="data.link"
          />
        </div>
      </div>
      <figure
        v-for="(i, index) in data.images"
        :key="i.id"
        class="figure"
        :data-speed="speedImages[index]"
      >
        <NakedImage
          v-if="i.responsiveImage"
          class="image"
          :data="i.responsiveImage"
        />
      </figure>
    </div>
  </div>
</template>

<script setup lang="ts">
import { NakedImage, StructuredText } from 'vue-datocms';
import gsap from 'gsap';
import type { TeaserAboutUsFragment } from '#gql';
import renderBlock from '~/datocms/renderBlock';
import Link from '~/components/partials/Link.vue';
import { useParallaxAnimation } from '~/utils/useParallaxAnimation';
import { removeTimelineAnimation } from '~/utils/removeTimelineAnimation';

const props = defineProps<{
  data: TeaserAboutUsFragment;
  horizontalScrollTimeline: gsap.core.Timeline;
}>();

const { isVl } = useViewportSize();

let tl: gsap.core.Timeline | null = null;
const tweens = ref<HTMLElement[]>([]);

const containerElement = ref<HTMLElement | null>(null);

const speedImages = [0.7, 1.2, 0.9, 0.9, 1, 0.75, 1.2];

useParallaxAnimation(containerElement, {
  onlyVs: true,
});

watch(isVl, function () {
  if (!isVl.value) {
    removeTimelineAnimation(tl, tweens);
  }
});

onMounted(async function () {
  await nextTick();

  if (containerElement.value && isVl.value) {
    addHorizontalAnimation(
      containerElement.value,
      props.horizontalScrollTimeline,
    );
  }
});

function addHorizontalAnimation(
  containerEl: HTMLElement,
  masterTl: gsap.core.Timeline,
) {
  const items = Array.from(containerEl.querySelectorAll('[data-speed]'));

  tl = gsap.timeline({
    ease: 'none',
    scrollTrigger: {
      trigger: containerEl,
      containerAnimation: masterTl,
      scrub: 0.5,
      horizontal: true,
    },
  });

  for (let i = 0; i < items.length; i++) {
    const item = items[i] as HTMLElement;
    const dataSpeed = Number(item.getAttribute('data-speed'));

    const x = (dataSpeed - 1) * window.innerWidth * 0.35;

    tweens.value.push(item);

    tl.fromTo(
      item,
      {
        x,
      },
      {
        x: x * -1,
      },
      '<',
    );
  }
}
</script>

<style scoped lang="scss">
.teaser-about-us {
  @media (--vs) {
    padding: 2.5rem var(--base-component-padding-inline);

    > .title {
      > .line2 {
        padding-left: 2ch;
      }
    }

    > .content {
      display: grid;
      grid-template-rows: repeat(8, auto);
      gap: 1rem;
      padding-top: 5rem;

      > .figure,
      > .textwrapper {
        &:nth-child(2n + 1) {
          justify-self: flex-end;
        }

        &:nth-child(2n + 0) {
          justify-self: flex-start;
        }
      }

      > .figure {
        @media (min-aspect-ratio: 1/1) {
          width: 70vh;
        }

        @media (max-aspect-ratio: 1/1) {
          width: 35vh;
        }
      }

      > .textwrapper {
        grid-row: 5 / 6;
        padding: 4rem 0 6rem 0;

        > .inner {
          text-align: right;

          > .text {
            text-align: left;
            padding-bottom: 2.5rem;
          }
        }
      }
    }
  }

  @media (--vl) {
    height: 100lvh;
    display: grid;
    grid-template-columns: auto auto;
    gap: 12rem;

    > .title {
      align-self: center;
      transform: translateY(1.65rem);

      > .line {
        display: block;
      }

      > .line2 {
        padding-left: 5ch;
      }
    }

    > .content {
      width: 250lvh;
      position: relative;
      margin: var(--margin-top) 0 4.25rem 0;

      > .figure,
      > .textwrapper {
        position: absolute;
      }

      > .figure {
        width: 100%;
        height: 100%;
        will-change: transform;

        :deep(picture) {
          display: block;
          width: 100%;
          height: 100%;

          img {
            width: 100% !important;
            height: 100% !important;
            object-fit: cover;
            max-width: unset !important;
          }
        }

        &:nth-child(2) {
          height: 35lvh;
          width: 28lvh;
          top: 0;
          left: 0;
        }

        &:nth-child(3) {
          height: 30lvh;
          width: 50lvh;
          bottom: 10lvh;
          left: 23lvh;
          z-index: 1;
        }

        &:nth-child(4) {
          height: 41lvh;
          width: 32.8lvh;
          top: 0;
          left: 44.815lvh;
        }

        &:nth-child(5) {
          height: 22lvh;
          width: 33lvh;
          top: 0;
          left: 100lvh;

          :deep(picture) {
            img {
              object-position: 50% 20%;
            }
          }
        }

        &:nth-child(6) {
          height: 22lvh;
          width: 33lvh;
          bottom: 0;
          left: 160lvh;

          :deep(picture) {
            img {
              object-position: 50% 70%;
            }
          }
        }

        &:nth-child(7) {
          height: 31.5lvh;
          width: 47.25lvh;
          top: 0;
          left: 183.5lvh;
        }

        &:nth-child(8) {
          height: 41.5lvh;
          width: 33.2lvh;
          left: 225lvh;
          bottom: 10lvh;
        }
      }

      > .textwrapper {
        hyphens: auto;
        max-width: 55ch;
        left: 89.3lvh;
        top: 33lvh;

        > .inner {
          //transform: translateY(calc(50lvh - 2rem));

          > .text {
            padding-bottom: 3rem;
          }
        }
      }
    }
  }
}
</style>
