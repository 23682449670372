<template>
  <div class="teaser-projects">
    <NuxtLink
      v-for="p in data.featuredProjects"
      ref="projectElement"
      :key="p.id"
      :to="{
        name: `projects-slug___${locale}`,
        params: { slug: p.translatedSlug },
      }"
      class="project"
    >
      <div class="imagewrapper" data-image-appear>
        <NakedImage
          v-if="p.imageLandscape?.image?.responsiveImage"
          picture-class="image -vl"
          :data="p.imageLandscape.image.responsiveImage"
        />
        <NakedImage
          v-if="p.imagePortrait?.image?.responsiveImage"
          picture-class="image -vs"
          :data="p.imagePortrait.image.responsiveImage"
        />
      </div>
      <div class="content">
        <p v-if="p.location" class="eyebrow text-md">
          {{ p.location }}
        </p>
        <p v-if="p.longTitle" class="text text-lg">{{ p.longTitle }}</p>
      </div>
    </NuxtLink>
  </div>
</template>

<script setup lang="ts">
import { NakedImage, StructuredText } from 'vue-datocms';
import type { TeaserProjectsFragment } from '#gql';
import renderBlock from '~/datocms/renderBlock';

const { locale } = useI18n();

defineProps<{
  data: TeaserProjectsFragment;
}>();
</script>

<style scoped lang="scss">
.teaser-projects {
  display: block;
  padding: 0 var(--base-component-padding-inline);

  > .project {
    display: block;
    outline: none;
    border: none;

    &:not(:last-child) {
      margin-bottom: 2.5rem;
    }

    @media (--vs) {
      > .imagewrapper {
        padding-bottom: 1rem;

        > .image.-vs {
          display: block;
        }

        > .image.-vl {
          display: none;
        }
      }

      @media (min-aspect-ratio: 2/1) {
        > .imagewrapper {
          > .image.-vs {
            display: none;
          }

          > .image.-vl {
            display: block;
            aspect-ratio: 50 / 16.5;

            :deep(img) {
              aspect-ratio: 50 / 16.5 !important;
              object-fit: cover;
            }
          }
        }
      }
    }

    @media (--vl) {
      @include grid-3;

      grid-template-rows: 1fr;
    }

    > .imagewrapper {
      grid-column: 1 / 3;

      > .image.-vs {
        display: none;
        aspect-ratio: 2 / 3;
      }

      > .image.-vl {
        display: block;
        aspect-ratio: 16 / 9;
      }

      :deep(img) {
        max-width: unset !important;
        width: 100% !important;
        height: 100% !important;
        object-fit: cover !important;
      }
    }

    > .content {
      grid-column: 3 / 4;
      align-self: flex-end;

      > .eyebrow {
        color: var(--color-stone-500, #6c6a6a);
        padding-bottom: 0.5rem;
      }
    }
  }
}
</style>
