<template>
  <NuxtLink v-if="route" :to="route" class="teaser-default">
    <div
      v-if="data.imageLandscape && data.imagePortrait"
      class="imagewrapper"
      data-image-appear
    >
      <NakedImage
        v-if="data.imageLandscape?.responsiveImage"
        picture-class="image -vl"
        :data="data.imageLandscape.responsiveImage"
      />
      <NakedImage
        v-if="data.imagePortrait?.responsiveImage"
        picture-class="image -vs"
        :data="data.imagePortrait.responsiveImage"
      />
    </div>
    <div class="content">
      <StructuredText
        v-if="data.text"
        class="text text-lg"
        :data="data.text"
        :render-block="renderBlock"
      />
    </div>
  </NuxtLink>
</template>

<script setup lang="ts">
import { NakedImage, StructuredText } from 'vue-datocms';
import type { TeaserDefaultFragment } from '#gql';
import renderBlock from '~/datocms/renderBlock';
import { getRouteObject } from '~/datocms/getRouteObject';

const { locale } = useI18n();

const props = defineProps<{
  data: TeaserDefaultFragment;
}>();

const route = getRoute();

function getRoute() {
  if (props.data.referenceLink) {
    return getRouteObject({
      slug: String(props.data.referenceLink.translatedSlug),
      pageType: props.data.referenceLink.__typename,
      locale: locale.value,
    });
  }

  return null;
}
</script>

<style scoped lang="scss">
.teaser-default {
  display: block;
  padding: 0 var(--base-component-padding-inline);
  outline: none;
  border: none;

  @media (--vs) {
    > .imagewrapper {
      padding-bottom: 1rem;

      > .image.-vs {
        display: block;
      }

      > .image.-vl {
        display: none;
      }
    }

    @media (min-aspect-ratio: 2/1) {
      > .imagewrapper {
        > .image.-vs {
          display: none;
        }

        > .image.-vl {
          display: block;
          aspect-ratio: 50 / 16.5;

          :deep(img) {
            aspect-ratio: 50 / 16.5 !important;
            object-fit: cover;
          }
        }
      }
    }
  }

  @media (--vl) {
    @include grid-3;

    grid-template-rows: 1fr;
  }

  > .imagewrapper {
    grid-column: 1 / 3;

    > .image.-vs {
      display: none;
      aspect-ratio: 2 / 3;
    }

    > .image.-vl {
      display: block;
      aspect-ratio: 16 / 9;
    }

    :deep(img) {
      max-width: unset !important;
      width: 100% !important;
      height: 100% !important;
      object-fit: cover !important;
    }
  }

  > .content {
    grid-column: 3 / 4;
    align-self: flex-end;
  }
}
</style>
