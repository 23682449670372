import gsap from 'gsap';

export function removeTimelineAnimation(
  tl: gsap.core.Timeline | null,
  tweens: Ref<HTMLElement[]>,
) {
  if (tl) {
    tl.scrollTrigger?.kill();
    tl.killTweensOf(tweens.value);
    tl.kill();
    tl = null;

    for (let i = 0; i < tweens.value.length; i++) {
      const tween = tweens.value[i];

      tween.style.transform = '';
    }
  }
}
