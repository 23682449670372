<template>
  <div ref="containerElement" class="teaser-highlight" data-horizontal-module>
    <h2 ref="titleEl" class="title">
      <span class="line line1 text-4xl">{{ data.titleLine1 }}</span
      ><br />
      <span class="line line2 text-4xl-display">{{ data.titleLine2 }}</span>
    </h2>
    <div ref="imageWrapperEl" class="imagewrapper">
      <NakedImage
        v-if="data.image && data.image.responsiveImage"
        picture-class="image"
        :data="data.image.responsiveImage"
      />
      <div ref="overlayEl" class="overlay"></div>
      <img
        v-if="data.gifImage"
        ref="gifEl"
        loading="lazy"
        class="gifimage"
        :src="data.gifImage.url"
        :alt="data.gifImage?.alt ?? ''"
      />
    </div>
    <div class="content">
      <StructuredText
        v-if="data.text"
        :data="data.text"
        class="text-lg text"
        :render-block="renderBlock"
      />
      <Link v-if="data.link" class="button-primary" :link="data.link" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { NakedImage, StructuredText } from 'vue-datocms';
import gsap from 'gsap';
import type { TeaserHighlightFragment } from '#gql';
import renderBlock from '~/datocms/renderBlock';
import Link from '~/components/partials/Link.vue';
import { removeTimelineAnimation } from '~/utils/removeTimelineAnimation';

const { isVl } = useViewportSize();

const props = defineProps<{
  data: TeaserHighlightFragment;
  horizontalScrollTimeline: gsap.core.Timeline;
}>();

const containerElement = ref<HTMLElement | null>(null);
const imageWrapperEl = ref<HTMLElement | null>(null);
const gifEl = ref<HTMLElement | null>(null);
const overlayEl = ref<HTMLElement | null>(null);

let tl: gsap.core.Timeline | null;
const tweens = ref<HTMLElement[]>([]);

watch(isVl, function () {
  if (!isVl.value) {
    removeTimelineAnimation(tl, tweens);
  }
});

onMounted(async function () {
  await nextTick();

  if (isVl.value) {
    addScrollListenerContainer();
  }

  addScrollListenerImageWrapper();
});

function addScrollListenerContainer() {
  tl = gsap.timeline({
    ease: 'none',
    scrollTrigger: {
      trigger: containerElement.value,
      containerAnimation: props.horizontalScrollTimeline,
      scrub: true,
      start: '-50%',
      end: '100%',
      horizontal: true,
    },
  });

  const scale = window.innerWidth * 0.2;

  const line1 = (containerElement.value as HTMLElement).querySelector(
    '.line1',
  ) as HTMLElement;
  const line2 = (containerElement.value as HTMLElement).querySelector(
    '.line2',
  ) as HTMLElement;

  tweens.value.push(line1);
  tweens.value.push(line2);

  tl.fromTo(
    line1,
    {
      x: 0.75 * scale,
    },
    {
      x: -0.5 * scale,
    },
  ).fromTo(
    line2,
    {
      x: 1.2 * scale,
    },
    {
      x: -0.8 * scale,
    },
    '<',
  );
}

function addScrollListenerImageWrapper() {
  let tl: gsap.core.Timeline;

  if (isVl.value) {
    tl = gsap.timeline({
      ease: 'none',
      scrollTrigger: {
        trigger: imageWrapperEl.value,
        containerAnimation: props.horizontalScrollTimeline,
        scrub: true,
        start: '-75%',
        end: '75%',
        horizontal: true,
      },
    });
  } else {
    tl = gsap.timeline({
      ease: 'none',
      scrollTrigger: {
        trigger: imageWrapperEl.value,
        scrub: 1,
        start: '-75%',
        end: '125%',
      },
    });
  }

  tl.fromTo(
    gifEl.value,
    {
      scale: 0,
    },
    {
      scale: 1,
    },
  )
    .fromTo(
      overlayEl.value,
      {
        opacity: 0,
      },
      {
        opacity: 0.6,
        ease: 'power1.in',
      },
      '<',
    )
    .to(overlayEl.value, {
      opacity: 0,
    });
}
</script>

<style scoped lang="scss">
.teaser-highlight {
  > .imagewrapper {
    > .overlay {
      position: absolute;
      inset: 0;
      width: 100%;
      height: 100%;
      background-color: var(--color-black);
      z-index: 1;
      opacity: 0.2;
    }
  }

  @media (--vs) {
    padding: 2.5rem 0 0 0;

    > .title,
    > .content {
      padding: 0 var(--base-component-padding-inline);
    }

    > .title {
      padding-bottom: 5rem;

      > .line {
        display: inline-block;
      }

      > .line2 {
        padding-left: 2ch;
      }
    }

    > .imagewrapper {
      position: relative;
      width: 100vw;
      height: 100lvh;
      display: flex;

      > .image {
        position: absolute;
        inset: 0;
        width: 100%;
        height: 100%;

        > :deep(img) {
          width: 100% !important;
          height: 100% !important;
          max-width: unset !important;
          object-fit: cover;
        }
      }

      > .gifimage {
        margin: auto;
        aspect-ratio: 2 / 3;
        max-height: 80%;
        max-width: 80%;
        z-index: 1;
        object-fit: contain;
      }
    }

    > .content {
      padding-top: 5rem;
      text-align: right;

      > .text {
        text-align: left;
        padding-bottom: 2.5rem;
      }
    }
  }

  @media (--vl) {
    height: 100lvh;
    display: grid;
    grid-template-columns: max-content 100vw calc(50ch + 16rem);
    gap: 5rem;

    > .title {
      align-self: center;
      transform: translateY(1.65rem);

      > .line {
        display: block;
      }

      > .line2 {
        padding-left: 3ch;
      }
    }

    > .imagewrapper {
      position: relative;
      width: 100vw;
      height: 100lvh;
      display: flex;

      > .image {
        position: absolute;
        inset: 0;
        height: 100%;
        width: 100%;

        > :deep(img) {
          object-fit: cover;
          height: 100% !important;
          width: 100% !important;
          max-width: unset !important;
        }
      }

      > .gifimage {
        margin: auto;
        //inset: 20% auto;
        aspect-ratio: 4 / 5;
        height: 60%;
        z-index: 1;
      }
    }

    > .content {
      padding-left: 7rem;
      padding-right: 4rem;
      transform: translateY(calc(50lvh - 2rem));

      > .text {
        padding-bottom: 3rem;
      }
    }
  }
}
</style>
