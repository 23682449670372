<template>
  <ContentText v-if="posting && posting === data.posting" :data="data" />
</template>

<script setup lang="ts">
import type { ContentTextGreetingFragment } from '#gql';
import ContentText from '~/components/modules/ContentText.vue';

defineProps<{
  data: ContentTextGreetingFragment;
}>();

const route = useRoute();

const posting = route.query.posting as string;
</script>

<style scoped lang="scss">
// no styles needed, this module just wraps content text and shows it depending on the utm_source query parameter
</style>
