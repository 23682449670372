<template>
  <div class="content-team">
    <h2 v-if="data.title" class="title text-md">{{ data.title }}</h2>
    <StructuredText
      v-if="data.text"
      class="lead"
      :data="data.text"
      :render-block="renderBlock"
    />
    <div class="content">
      <ul class="list">
        <li v-for="it in data.teamMembers" :key="it.id" class="member">
          <div ref="imageWrapperEl" class="image-wrapper" data-image-appear>
            <Image
              v-if="it.image && it.image.responsiveImage"
              layout="fill"
              class="image"
              :img-style="{ objectFit: 'cover' }"
              :data="it.image.responsiveImage"
            />
          </div>
          <p class="name text-md">{{ it.name }}</p>
          <p class="role text-md-regular">{{ it.role }}</p>
          <p class="email text-sm">
            <a class="link" :href="`mailto:${it.eMail}`">{{ it.eMail }}</a>
          </p>
          <p class="phone text-sm">
            <a class="link" :href="`tel:${it.phone}`">{{ it.phone }}</a>
          </p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Image, StructuredText } from 'vue-datocms';
import type { ContentTeamFragment } from '#gql';
import renderBlock from '~/datocms/renderBlock';

const { isVs, isVl, isVlNarrow, isVsWide } = useViewportSize();

defineProps<{
  data: ContentTeamFragment;
}>();

const imageWrapperEl = ref<HTMLElement[]>([]);

onMounted(function () {
  let columns = 0;

  if (isVsWide.value || isVlNarrow.value) {
    columns = 2;
  } else if (isVs.value) {
    columns = 1;
  } else if (isVl.value) {
    columns = 3;
  }

  for (let i = 0; i < imageWrapperEl.value.length; i++) {
    const el = imageWrapperEl.value[i];

    el.setAttribute('data-image-appear-delay', String((i % columns) * 0.2));
  }
});
</script>

<style scoped lang="scss">
.content-team {
  display: grid;
  padding: 0 var(--base-component-padding-inline);

  @media (--vs) {
    grid-template-columns: repeat(1, auto);

    > .title {
      margin-bottom: 1.5rem;
    }

    > .lead {
      margin-bottom: 5rem;
    }

    > .content {
      grid-column-start: 1;

      > .list {
        --member-columns: 1;
      }
    }

    @media (min-aspect-ratio: 1/1) {
      > .content > .list {
        --member-columns: 2;
      }
    }
  }

  @media (--vl) {
    grid-template-columns: 21.66675vw auto;
    column-gap: var(--base-component-padding-inline);

    > .lead {
      margin-bottom: 7.5rem;
    }

    > .content {
      grid-column-start: 2;

      > .list {
        --member-columns: 3;
      }
    }

    @media (max-aspect-ratio: 1/1) {
      > .content > .list {
        --member-columns: 2;
      }
    }
  }

  > .content {
    > .list {
      display: grid;
      grid-template-columns: repeat(var(--member-columns), minmax(0, 1fr));
      gap: var(--base-component-padding-inline);

      > .member {
        position: relative;

        > .image-wrapper {
          position: relative;
          aspect-ratio: 4 / 5;
          margin-bottom: 1rem;
        }

        > .email {
          margin-top: 1.5rem;
          margin-bottom: 0.5rem;
        }

        > .email,
        > .phone {
          > .link {
            border-bottom: 1px solid currentColor;
          }
        }
      }
    }
  }
}
</style>
