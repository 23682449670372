<template>
  <div ref="containerEl" class="content-image-wall">
    <h2 v-if="data.title" class="title text-md">{{ data.title }}</h2>
    <StructuredText
      v-if="data.text"
      class="lead text-xl"
      :data="data.text"
      :render-block="renderBlock"
    />
    <div class="images">
      <ImageWithCaption
        v-for="(i, index) in data.imagesWithCaption"
        :key="i.id"
        class="imagewithcaption"
        :data="i"
        data-image-appear
        :format="formatOrder[index]"
        :class="[`-${formatOrder[index]}`]"
        :data-speed="speedImages[index]"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { StructuredText } from 'vue-datocms';
import type { ContentImageWallFragment } from '#gql';
import renderBlock from '~/datocms/renderBlock';
import ImageWithCaption from '~/components/partials/ImageWithCaption.vue';
import { useParallaxAnimation } from '~/utils/useParallaxAnimation';

defineProps<{
  data: ContentImageWallFragment;
}>();

const containerEl = ref<HTMLElement | null>(null);

const formats: Record<string, 'portrait' | 'landscape'> = {
  portrait: 'portrait',
  landscape: 'landscape',
};

const formatOrder = [
  formats.portrait,
  formats.landscape,
  formats.portrait,
  formats.landscape,
  formats.portrait,
  formats.portrait,
  formats.landscape,
];

const speedImages = [0.75, 1.2, 0.95, 1, 0.9, 1.05, 1.2];

useParallaxAnimation(containerEl);

onMounted(async function () {
  await nextTick();
});
</script>

<style scoped lang="scss">
.content-image-wall {
  @include module-grid;

  & {
    padding: 0 var(--base-component-padding-inline);
  }

  > .images {
    @media (--vs) and (max-aspect-ratio: 1/1) {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
      grid-area: 3 / 1 / 4 / 2;

      > .imagewithcaption {
        width: 35vh;

        &:nth-child(2n + 2) {
          margin-right: 0;
          margin-left: auto;
          margin-top: 1rem;
        }
      }
    }

    @media (--vs) and (min-aspect-ratio: 1/1) {
      display: flex;
      flex-direction: column;
      gap: 3.5rem;
      grid-area: 3 / 1 / 4 / 2;

      > .imagewithcaption {
        &.-portrait {
          width: calc(calc(100lvh + 3rem) / 3 * 2);
        }

        &.-landscape {
          width: 50vw;
        }

        &:nth-child(2n + 2) {
          margin-right: 0;
          margin-left: auto;
        }
      }
    }

    @media (--vl) and (min-aspect-ratio: 20/10) {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 100% !important;

      > .imagewithcaption {
        position: unset;

        &:nth-child(odd) {
          margin-left: 10vw;
          margin-right: auto;
        }

        &:nth-child(even) {
          margin-right: 10vw;
          margin-left: auto;
        }
      }
    }

    @media (--vl) {
      position: relative;
      grid-area: 2 / 1 / 3 / 3;

      &:has(:first-child:nth-last-child(4)) {
        // calc -> top of 4. element + height of 4. element + caption & puffer
        height: calc(38rem + 17.7vw + 10rem);
      }

      &:has(:first-child:nth-last-child(5)) {
        // calc -> top of 5. element + height of 5. element + caption & puffer
        height: calc(54rem + 30vw + 2rem);
      }

      &:has(:first-child:nth-last-child(6)) {
        // calc -> top of 6. element + height of 6. element + caption & puffer
        height: calc(65rem + 30vw + 5rem);
      }

      &:has(:first-child:nth-last-child(7)) {
        // calc -> top of 7. element + height of 7. element + caption & puffer
        height: calc(88rem + 17.7vw);
      }

      > .imagewithcaption {
        position: absolute;
        height: auto;

        &.-landscape {
          width: 26.5vw;

          :deep(picture) {
            aspect-ratio: 3 / 2;
            width: 26.5vw;
            height: 17.667vw;
          }
        }

        &.-portrait {
          width: 24vw;

          :deep(picture) {
            aspect-ratio: 4 / 5;
            width: 24vw;
            height: 30vw;
          }
        }

        :deep(picture) {
          img {
            width: 100% !important;
            height: 100% !important;
            max-width: unset !important;
            object-fit: cover;
          }
        }

        &:nth-child(1) {
          top: 0;
          left: 0;
        }

        &:nth-child(2) {
          top: 4rem;
          left: 38vw;
        }

        &:nth-child(3) {
          top: 15rem;
          right: 0;
        }

        &:nth-child(4) {
          top: 38rem;
          left: 22vw;
        }

        &:nth-child(5) {
          top: 54rem;
          right: 5rem;
        }

        &:nth-child(6) {
          top: 68rem;
          left: 2rem;
        }

        &:nth-child(7) {
          top: 85rem;
          left: 30vw;
        }
      }
    }

    > .imagewithcaption {
      text-align: left;

      :deep(img) {
        width: 100% !important;
        max-width: unset !important;
      }

      :deep(.caption) {
        @extend .text-md;

        @media (min-aspect-ratio: 20/10) {
          font-size: 1rem;
          line-height: 120%;
        }

        & {
          padding-top: 1rem;
        }
      }
    }
  }
}
</style>
