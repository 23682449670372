<template>
  <div ref="containerEl" class="teaser-list">
    <h2 v-if="data.title" class="title text-md">{{ data.title }}</h2>
    <ul class="list">
      <li
        v-for="item in data.links"
        :key="`teaser-list-item-${item.id}`"
        class="item"
      >
        <Link
          v-if="item"
          class="text-xl link button-primary"
          data-list-anchor
          :link="item"
        />
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import gsap from 'gsap';
import type { TeaserListFragment } from '#gql';
import Link from '~/components/partials/Link.vue';
import { useIntersectionState } from '~/utils/useIntersectionState';

defineProps<{
  data: TeaserListFragment;
}>();

const containerEl = ref<HTMLElement | null>(null);
let tl: gsap.core.Timeline | null = null;

useIntersectionState(containerEl, {
  onFirstEnter: playAnimation,
});

onMounted(function () {
  addLinesAnimation();
});

function playAnimation() {
  tl?.play(0);
}

function addLinesAnimation() {
  const elements = gsap.utils.toArray('.teaser-list .list .item');
  tl = gsap.timeline({
    paused: true,
  });

  tl.fromTo(
    elements,
    {
      '--scaleX': 0,
    },
    {
      '--scaleX': 1,
      stagger: 0.35,
      duration: 1,
      ease: 'power1.in',
    },
  );
}
</script>

<style scoped lang="scss">
.teaser-list {
  @include module-grid;

  & {
    padding: 0 var(--base-component-padding-inline);
  }

  @media (--vl) {
    grid-row-gap: 0;

    > .title {
      grid-area: title;
    }

    > .list {
      grid-area: lead / content;
    }
  }

  > .list {
    > .item {
      position: relative;

      &:hover {
        &::before,
        &::after {
          background-color: var(--color-stone-900, #0b0b0a);
          z-index: 2;
        }
      }

      &::before,
      &::after {
        content: '';
        position: absolute;
        inset: auto 0 0 0;
        width: 100%;
        height: 1px;
        background-color: var(--color-stone-300, #e0e0e0);
        scale: var(--scaleX) 1;
        transform-origin: left center;
        transition: background-color 300ms;
      }

      &::before {
        inset: -1px 0 auto 0;
      }

      &::after {
        inset: auto 0 0 0;
      }

      > .link {
        --size-arrow: 3rem;

        width: 100%;
        justify-content: space-between;
        outline: none !important;
        border: none !important;

        @media (--vs) {
          padding: 1rem 0;
        }

        @media (--vl) {
          padding: 1.5rem 0;
        }
      }
    }
  }
}
</style>
