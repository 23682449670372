import gsap from 'gsap';
import { removeTimelineAnimation } from '~/utils/removeTimelineAnimation';

export function useParallaxAnimation(
  container: Ref<HTMLElement | null>,
  options: { onlyVs: boolean } = { onlyVs: false },
) {
  let scrollTl: gsap.core.Timeline | null = null;
  const tweens = ref<HTMLElement[]>([]);

  const { isVs, isVl } = useViewportSize();

  watch(isVl, function () {
    if (!container.value || !isMatchingViewport()) {
      removeAnimation();
    }
  });

  onMounted(function () {
    init();
  });

  function removeAnimation() {
    removeTimelineAnimation(scrollTl, tweens);
  }

  async function init() {
    if (!container.value || !isMatchingViewport()) {
      removeAnimation();
    } else {
      await nextTick();
      await useWait(500);

      const items = Array.from(
        container.value.querySelectorAll('[data-speed]'),
      );

      scrollTl = gsap.timeline({
        ease: 'none',
        scrollTrigger: {
          trigger: container.value,
          scrub: 1,
        },
      });

      // if vs -> ignore first item
      const startI = isVs.value ? 1 : 0;

      // if vs -> also ignore last item
      const endI = isVs.value ? items.length - 1 : items.length;

      for (let i = startI; i < endI; i++) {
        const item = items[i] as HTMLElement;
        tweens.value.push(item);

        const dataSpeed = Number(item.getAttribute('data-speed'));

        const factor = isVs.value ? 0.35 : 0.65;

        const y = (dataSpeed - 1) * (window.innerHeight * factor);

        scrollTl.fromTo(
          item,
          {
            y,
          },
          {
            y: y * -1,
          },
          '<',
        );
      }
    }
  }

  function isMatchingViewport() {
    return !(isVl.value && options.onlyVs);
  }
}
