<template>
  <div ref="containerEl" class="content-list">
    <h2 v-if="data.title" class="title text-md">{{ data.title }}</h2>
    <StructuredText
      v-if="data.text"
      :data="data.text"
      class="lead text-xl"
      :render-block="renderBlock"
    />
    <ul class="list">
      <ListItem v-for="it in data.listItems" :key="it.id" :data="it" />
    </ul>
  </div>
</template>

<script setup lang="ts">
import gsap from 'gsap';
import { StructuredText } from 'vue-datocms';
import type { ContentListFragment } from '#gql';
import renderBlock from '~/datocms/renderBlock';
import ListItem from '~/components/partials/ListItem.vue';
import { useIntersectionState } from '~/utils/useIntersectionState';

defineProps<{
  data: ContentListFragment;
}>();

const containerEl = ref<HTMLElement | null>(null);
let tl: gsap.core.Timeline | null = null;

useIntersectionState(containerEl, {
  onFirstEnter: playAnimation,
});

onMounted(function () {
  addLinesAnimation();
});

function playAnimation() {
  tl?.play(0);
}

function addLinesAnimation() {
  const elements = gsap.utils.toArray('.content-list .list-item');
  tl = gsap.timeline({
    paused: true,
  });

  tl.fromTo(
    elements,
    {
      '--scaleX': 0,
    },
    {
      '--scaleX': 1,
      stagger: 0.35,
      duration: 1,
      ease: 'power2.in',
    },
  );
}
</script>

<style scoped lang="scss">
.content-list {
  @include module-grid;

  & {
    padding: 0 var(--base-component-padding-inline);
  }

  @media (--vs) {
  }

  @media (--vl) {
    > .title {
      grid-area: title;
    }

    > .lead {
      grid-area: lead;
    }

    > .list {
      grid-area: content;
    }
  }
}
</style>
